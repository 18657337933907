import React from 'react';
import { graphql } from 'gatsby';
import Page from '@components/Page';
import FormBot from '@components/FormBot';
import BackgroundImage from 'gatsby-background-image';

//FIXME: move to cms
// eslint-disable-next-line complexity
const getTags = code => [
  {
    id: 'email',
    name: 'email',
    tag: 'input',
    type: 'email',
    required: true,
    'cf-questions': code === 'pl' ? 'Jaki jest Twój adres e-mail?' : 'What is your email address?'
  },
  {
    id: 'firstname',
    name: 'firstname',
    tag: 'input',
    type: 'text',
    required: true,
    'cf-questions': code === 'pl' ? 'Jak masz na imię?' : 'What is first name?'
  },
  {
    id: 'lastname',
    name: 'lastname',
    tag: 'input',
    type: 'text',
    required: true,
    'cf-questions': code === 'pl' ? 'Jak masz na nazwisko?' : 'What is last name?'
  },
  {
    id: 'company',
    name: 'company',
    tag: 'input',
    type: 'text',
    required: true,
    'cf-questions': code === 'pl' ? 'Cześć {firstname}! Jaką firmę reprezentujesz?' : 'Hi {firstname}! What company do you represent?'
  },
  {
    id: 'venues',
    name: 'venues',
    tag: 'input',
    type: 'number',
    required: true,
    'cf-questions': code === 'pl' ? 'Ile lokali prowadzi {company}?' : 'How many venues does {company} operate?'
  },
  {
    // tag group
    tag: 'fieldset',
    type: 'Checkboxes',
    id: 'model',
    'cf-questions': code === 'pl' ? 'Jaki typ lokali prowadzi {company}?' : 'What kind of venues does {company} operate?',
    children: code === 'pl' ? [
      { tag: 'input', type: 'checkbox', name: 'model', 'cf-label': 'Bar', value: 'Bar' },
      { tag: 'input', type: 'checkbox', name: 'model', 'cf-label': 'Restauracja', value: 'Restaurant' },
      { tag: 'input', type: 'checkbox', name: 'model', 'cf-label': 'Hotel', value: 'Hotel' },
      { tag: 'input', type: 'checkbox', name: 'model', 'cf-label': 'Kasyno', value: 'Casino' },
      { tag: 'input', type: 'checkbox', name: 'model', 'cf-label': 'Catering', value: 'Catering' },
      { tag: 'input', type: 'checkbox', name: 'model', 'cf-label': 'Inny', value: 'Other' }
    ] : [
      { tag: 'input', type: 'checkbox', name: 'model', 'cf-label': 'Bar', value: 'Bar' },
      { tag: 'input', type: 'checkbox', name: 'model', 'cf-label': 'Restaurant', value: 'Restaurant' },
      { tag: 'input', type: 'checkbox', name: 'model', 'cf-label': 'Hotel', value: 'Hotel' },
      { tag: 'input', type: 'checkbox', name: 'model', 'cf-label': 'Casino', value: 'Casino' },
      { tag: 'input', type: 'checkbox', name: 'model', 'cf-label': 'Catering', value: 'Catering' },
      { tag: 'input', type: 'checkbox', name: 'model', 'cf-label': 'Other', value: 'Other' }
    ]
  },
  {
    // select group
    tag: 'fieldset',
    id: 'role',
    'cf-questions': code === 'pl' ? 'Jaka jest Twoja rola w {company}?' : 'What is your role at {company}?',
    isMultiChoice: false,
    children: code === 'pl' ? [
      { tag: 'input', type: 'radio', name: 'role', 'cf-label': 'Właściciel', value: 'Owner' },
      { tag: 'input', type: 'radio', name: 'role', 'cf-label': 'Manager', value: 'Manager' },
      { tag: 'input', type: 'radio', name: 'role', 'cf-label': 'Szef kuchni', value: 'Chef' },
      { tag: 'input', type: 'radio', name: 'role', 'cf-label': 'Barman', value: 'Bartender' },
      { tag: 'input', type: 'radio', name: 'role', 'cf-label': 'Logistyka', value: 'Operations' },
      { tag: 'input', type: 'radio', name: 'role', 'cf-label': 'Księgowość', value: 'Accounting' },
      { tag: 'input', type: 'radio', name: 'role', 'cf-label': 'Inne', value: 'Other' }
    ] : [
      { tag: 'input', type: 'radio', name: 'role', 'cf-label': 'Owner', value: 'Owner' },
      { tag: 'input', type: 'radio', name: 'role', 'cf-label': 'Operations', value: 'Operations' },
      { tag: 'input', type: 'radio', name: 'role', 'cf-label': 'Accounting', value: 'Accounting' },
      { tag: 'input', type: 'radio', name: 'role', 'cf-label': 'Manager', value: 'Manager' },
      { tag: 'input', type: 'radio', name: 'role', 'cf-label': 'Chef', value: 'Chef' },
      { tag: 'input', type: 'radio', name: 'role', 'cf-label': 'Bartender', value: 'Bartender' },
      { tag: 'input', type: 'radio', name: 'role', 'cf-label': 'Other', value: 'Other' }
    ]
  },
  {
    // select group
    tag: 'fieldset',
    id: 'inventory',
    'cf-questions': code === 'pl' ? 'Jak obecnie przeprowadzane są inwentaryzacje?' : 'How do you control your inventory?',
    isMultiChoice: false,
    children: code === 'pl' ? [
      { tag: 'input', type: 'radio', name: 'inventory', 'cf-label': 'Papier i długopis', value: 'Pen and paper' },
      { tag: 'input', type: 'radio', name: 'inventory', 'cf-label': 'Arkusz kalkulacyjny', value: 'Spreadsheets' },
      { tag: 'input', type: 'radio', name: 'inventory', 'cf-label': 'System POS', value: 'POS System' },
      { tag: 'input', type: 'radio', name: 'inventory', 'cf-label': 'Program magazynowy', value: 'Inventory Management Software' },
      { tag: 'input', type: 'radio', name: 'inventory', 'cf-label': 'Inne', value: 'Other' }
    ] : [
      { tag: 'input', type: 'radio', name: 'inventory', 'cf-label': 'Pen & Paper', value: 'Pen and paper' },
      { tag: 'input', type: 'radio', name: 'inventory', 'cf-label': 'Spreadsheet', value: 'Spreadsheets' },
      { tag: 'input', type: 'radio', name: 'inventory', 'cf-label': 'POS System', value: 'POS System' },
      { tag: 'input', type: 'radio', name: 'inventory', 'cf-label': 'Inventory Management Software', value: 'Inventory Management Software' },
      { tag: 'input', type: 'radio', name: 'inventory', 'cf-label': 'Other', value: 'Other' }
    ]
  },
  {
    // select group
    tag: 'fieldset',
    id: 'pos',
    'cf-questions': code === 'pl' ? 'Jakiego systemu Point-of-Sale używasz?' : 'What kind of Point-of-Sale system you use?',
    isMultiChoice: false,
    children: code === 'pl' ? [
      { tag: 'input', type: 'radio', name: 'pos', 'cf-label': 'Oracle Micros', value: 'Oracle Micros' },
      { tag: 'input', type: 'radio', name: 'pos', 'cf-label': 'FoodSoft', value: 'FoodSoft' },
      { tag: 'input', type: 'radio', name: 'pos', 'cf-label': 'Flow/Amber POS', value: 'Amber POS' },
      { tag: 'input', type: 'radio', name: 'pos', 'cf-label': 'Gastro POS', value: 'Gastro POS' },
      { tag: 'input', type: 'radio', name: 'pos', 'cf-label': 'Nomee', value: 'Nomee' },
      { tag: 'input', type: 'radio', name: 'pos', 'cf-label': 'Dotykačka', value: 'Dotykačka' },
      { tag: 'input', type: 'radio', name: 'pos', 'cf-label': 'POSBistro', value: 'POSBistro' },
      { tag: 'input', type: 'radio', name: 'pos', 'cf-label': 'Inny', value: 'Other' }
    ] : [
      { tag: 'input', type: 'radio', name: 'pos', 'cf-label': 'Oracle Micros', value: 'Oracle Micros' },
      { tag: 'input', type: 'radio', name: 'pos', 'cf-label': 'NCR Aloha', value: 'NCR Aloha' },
      { tag: 'input', type: 'radio', name: 'pos', 'cf-label': 'Maitre\'D', value: 'Maitre\'D' },
      { tag: 'input', type: 'radio', name: 'pos', 'cf-label': 'Brink POS', value: 'Brink POS' },
      { tag: 'input', type: 'radio', name: 'pos', 'cf-label': 'Lavu', value: 'Lavu' },
      { tag: 'input', type: 'radio', name: 'pos', 'cf-label': 'Lightspeed', value: 'Lightspeed' },
      { tag: 'input', type: 'radio', name: 'pos', 'cf-label': 'Toast', value: 'Toast' },
      { tag: 'input', type: 'radio', name: 'pos', 'cf-label': 'POS itouch', value: 'POS itouch' },
      { tag: 'input', type: 'radio', name: 'pos', 'cf-label': 'Dinerware', value: 'Dinerware' },
      { tag: 'input', type: 'radio', name: 'pos', 'cf-label': 'Touch Bistro', value: 'Touch Bistro' },
      { tag: 'input', type: 'radio', name: 'pos', 'cf-label': 'Other', value: 'Other' }
    ]
  },
  {
    // tag group
    tag: 'fieldset',
    type: 'Checkboxes',
    id: 'features',
    'cf-questions': code === 'pl' ? 'Które funkcje systemu No Spoilers są dla Ciebie ważne? ' : 'Which No Spoilers features are important for you?',
    children: code === 'pl' ? [
      { tag: 'input', type: 'checkbox', name: 'features', 'cf-label': 'Inwentaryzacja baru', value: 'Bar Inventory Control' },
      { tag: 'input', type: 'checkbox', name: 'features', 'cf-label': 'Inwentaryzacja kuchni', value: 'Kitchen Inventory Control' },
      { tag: 'input', type: 'checkbox', name: 'features', 'cf-label': 'Automatyczne zamówienia dostaw', value: 'Automated Supply Orders' },
      { tag: 'input', type: 'checkbox', name: 'features', 'cf-label': 'Kontrola dostaw', value: 'Incoming Order Control' },
      { tag: 'input', type: 'checkbox', name: 'features', 'cf-label': 'Kontrola strat', value: 'Waste Control' },
      { tag: 'input', type: 'checkbox', name: 'features', 'cf-label': 'Generowanie raportów', value: 'Report Generation' },
      { tag: 'input', type: 'checkbox', name: 'features', 'cf-label': 'Inne', value: 'Other' }
    ] : [
      { tag: 'input', type: 'checkbox', name: 'features', 'cf-label': 'Bar Inventory Control', value: 'Bar Inventory Control' },
      { tag: 'input', type: 'checkbox', name: 'features', 'cf-label': 'Kitchen Inventory Control', value: 'Kitchen Inventory Control' },
      { tag: 'input', type: 'checkbox', name: 'features', 'cf-label': 'Automated Supply Orders', value: 'Automated Supply Orders' },
      { tag: 'input', type: 'checkbox', name: 'features', 'cf-label': 'Incoming Order Control', value: 'Incoming Order Control' },
      { tag: 'input', type: 'checkbox', name: 'features', 'cf-label': 'Waste Control', value: 'Waste Control' },
      { tag: 'input', type: 'checkbox', name: 'features', 'cf-label': 'Report Generation', value: 'Report Generation' },
      { tag: 'input', type: 'checkbox', name: 'features', 'cf-label': 'Other', value: 'Other' }
    ]
  },
  {
    id: 'source',
    tag: 'fieldset',
    'cf-questions': code === 'pl' ? 'Ostatnie pytanie. Skąd dowiedziałeś się o No Spoilers?' : 'Last question. How did you learn about No Spoilers?',
    isMultiChoice: false,
    children: code === 'pl' ? [
      { tag: 'input', type: 'radio', name: 'source', 'cf-label': 'Wyszukiwarka', value: 'Internet Search' },
      { tag: 'input', type: 'radio', name: 'source', 'cf-label': 'Serwisy społecznościowe', value: 'Social Media' },
      { tag: 'input', type: 'radio', name: 'source', 'cf-label': 'Znajomy lub współpracownik', value: 'Friend or Colleague' },
      { tag: 'input', type: 'radio', name: 'source', 'cf-label': 'Konferencja lub targi', value: 'Conference or Tradeshow' },
      { tag: 'input', type: 'radio', name: 'source', 'cf-label': 'Email', value: 'Email' },
      { tag: 'input', type: 'radio', name: 'source', 'cf-label': 'Podcast', value: 'Podcast' },
      { tag: 'input', type: 'radio', name: 'source', 'cf-label': 'Blog', value: 'Blog' },
      { tag: 'input', type: 'radio', name: 'source', 'cf-label': 'Wiadomości', value: 'News' },
      { tag: 'input', type: 'radio', name: 'source', 'cf-label': 'Nasz partner', value: 'Our Partner' },
      { tag: 'input', type: 'radio', name: 'source', 'cf-label': 'Inne', value: 'Other' }
    ] : [
      { tag: 'input', type: 'radio', name: 'source', 'cf-label': 'Internet search', value: 'Internet Search' },
      { tag: 'input', type: 'radio', name: 'source', 'cf-label': 'Social media', value: 'Social Media' },
      { tag: 'input', type: 'radio', name: 'source', 'cf-label': 'Friend or Colleague', value: 'Friend or Colleague' },
      { tag: 'input', type: 'radio', name: 'source', 'cf-label': 'Conference or Tradeshow', value: 'Conference or Tradeshow' },
      { tag: 'input', type: 'radio', name: 'source', 'cf-label': 'Email', value: 'Email' },
      { tag: 'input', type: 'radio', name: 'source', 'cf-label': 'Podcast', value: 'Podcast' },
      { tag: 'input', type: 'radio', name: 'source', 'cf-label': 'Blog', value: 'Blog' },
      { tag: 'input', type: 'radio', name: 'source', 'cf-label': 'News', value: 'News' },
      { tag: 'input', type: 'radio', name: 'source', 'cf-label': 'Our Partner', value: 'Our Partner' },
      { tag: 'input', type: 'radio', name: 'source', 'cf-label': 'Other', value: 'Other' }
    ]
  }
];

export default function QuizTemplate({ data: { pricingBG, nsds }, pageContext: { language }}) {
  const redirect = `/${language.code === 'pl' ? `${language.code}/` : ''}${language.code === 'pl' ? 'termin-demo' : 'book-a-demo'}`;
  return (
    <Page>
      <BackgroundImage fluid={pricingBG.sharp.fluid} style={{ height: '100vh', width: '100vw' }}>
        <FormBot robotImage={nsds.sharp.fixed.src} language={language} tags={getTags(language.code)} redirect={redirect} />
      </BackgroundImage>
    </Page>
  );
}

export const query = graphql`
  query($id: String) {
    nsds: file(relativePath: { eq: "nsds.png" }) {
      sharp: childImageSharp {
        fixed(width: 176, height: 176) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    pricingBG: file(relativePath: { eq: "pricing-bg.png" }) {
      sharp: childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    page: wpWebsite(id: { eq: $id }) {
      ...Page
    }
  }
`;