import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import { navigate, withPrefix } from 'gatsby';
import { getTagsFromSessionStorage } from '@lib/searchQuery';
import { ConversationalFormLocale } from '@lib/i18n';

export default class FormBot extends React.Component {

  componentDidMount() {
    const { robotImage, tags, redirect, language } = this.props;
    const locales = ConversationalFormLocale[language.code] || ConversationalFormLocale['en'];

    const handleUserSubmit = ({ detail }) => setTimeout(() => {
      const value = this.form.getFormData(true)[detail.tag.id];
      let slackText = `*[${this.email}]* ${detail.tag.questions[0]} *${detail.text}*`;

      if (detail.tag.id === 'email') {
        this.email = detail.text;
        slackText = `*[${this.email}]* Started New Conversational Quiz Form`;
      }

      const body = {
        email: this.email,
        inputId: detail.tag.id,
        question: detail.tag.questions[0],
        answer: Array.isArray(value) ? value.join(';') : value,
        slackText,
        language
      };

      axios.post(
        'https://n8n.nospoilers.ai/webhook/65873b65-218a-484f-a80d-6caaca228eb6',
        JSON.stringify(Object.assign(body, getTagsFromSessionStorage())),
        { headers: { 'Content-Type': 'text/plain' }}
      )
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.error(error);
        });
    }, 500);

    const initializeForm = () => {
      if (!window || !window.cf)
        return false;
      clearInterval(this.intervalId);
      const dispatcher = new window.cf.EventDispatcher();
      dispatcher.addEventListener(window.cf.UserInputEvents.SUBMIT, handleUserSubmit, false);

      this.form = window.cf.ConversationalForm.startTheConversation({
        tags,
        options: {
          showProgressBar: true,
          eventDispatcher: dispatcher,
          dictionaryData: locales.dictionaryData,
          robotImage,
          userInterfaceOptions: {
            controlElementsInAnimationDelay: 250,
            robot: {
              robotResponseTime: 0,
              chainedResponseTime: 400
            }
          },
          submitCallback: () => {
            var { email, firstname, lastname, company } = this.form.getFormData(true);
            console.log({ email, firstname, lastname });
            this.form.addRobotChatResponse(`${locales.robot.finish}&&${locales.robot.thanks}&&${locales.robot.redirect}`);
            setTimeout(() => {
              navigate(`${redirect}?email=${email}&firstname=${firstname}&lastname=${lastname}&company=${company}`);
            }, 5000);
          }
        }

      });
      this.elem.appendChild(this.form.el);
    };

    this.intervalId = setInterval(initializeForm, 1000);
  }

  render() {
    return (
      <Fragment>
        <Helmet>
          <style type="text/css">
            {`
            cf-input { background: transparent !important; }
            cf-input .inputWrapper { background: white !important; }
            .cf-progressBar { background-color: #8ac543 !important; }
            .conversational-form { background: transparent !important; }
            .conversational-form:before { background: transparent !important; }
          `}
          </style>
          <script type="text/javascript" src={withPrefix('conversational-form.min.js')}></script>
        </Helmet>
        <div ref={ref => this.elem = ref} />
      </Fragment>
    );
  }
}