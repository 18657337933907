export const ConversationalFormLocale = {
  en: {
    dictionaryData: {
      'entry-not-found': 'Dictionary item not found.',
      'input-placeholder': 'Type your answer here...',
      'group-placeholder': 'Select your answer. Type to filter...',
      'input-placeholder-error': 'Your input is not correct.',
      'input-placeholder-required': 'Input is required.',
      'input-placeholder-file-error': 'File upload failed.',
      'input-placeholder-file-size-error': 'File size too big.',
      'input-no-filter': 'No results found for ‛{input-value}‛',
      'user-reponse-and': ' and ',
      'user-reponse-missing': 'Missing input.',
      'user-reponse-missing-group': 'Nothing selected.',
      general: 'General type1||General type2'
    },
    robot: {
      finish: 'That\'s all!',
      thanks: 'Thank You for answering our questions!',
      redirect: 'In a moment we will redirect you to our Demo Booking page.'
    }
  },
  pl: {
    dictionaryData: {
      'entry-not-found': 'Nie znaleziono klucza słownika.',
      'input-placeholder': 'Wpisz swoją odpowiedź tutaj...',
      'group-placeholder': 'Wybierz odpowiedź. Zacznij pisać aby filtrować...',
      'input-placeholder-error': 'Wprowadzone dane są nieprawidłowe.',
      'input-placeholder-required': 'Dane są wymagane.',
      'input-placeholder-file-error': 'Nie udało się wczytać pliku.',
      'input-placeholder-file-size-error': 'Plik jest zbyt duży.',
      'input-no-filter': 'Nie znaleziono wyników dla ‛{input-value}‛',
      'user-reponse-and': ' i ',
      'user-reponse-missing': 'Brakujące dane...',
      'user-reponse-missing-group': 'Nic nie zostało wybrane...',
      general: 'Ogólne typ1||Ogólne typ2'
    },
    robot: {
      finish: 'To już wszystkie pytania.',
      thanks: 'Dziękujemy za Twoje odpowiedzi!',
      redirect: 'Za moment przekierujemy Cię na stronę rezerwacji terminu prezentacji programu.'
    }
  }
};